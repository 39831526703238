<template>
  <div class="container product">
    <h1>{{$t('Hr.title')}}</h1>
    <div class="py-3"></div>
    <div class="row">
      <div class="mx-auto col-12 col-md-10 col-lg-8">
        <div class="d-flex flex-column">
          <h3 class="align-self-start">{{$t('Hr.content1')}}</h3>
          <h3 class="align-self-end">{{$t('Hr.content2')}}</h3>
          <div class="py-2"></div>
          <h3 class="align-self-start">{{$t('Hr.content3')}}</h3>
          <div class="py-2"></div>
          <div class="px-md-4 mx-md-4 align-self-stretch">
            <img src="@/assets/hr/hr1.png" class="img-fluid" alt="" />
          </div>
          <div class="py-4"></div>
          <div class="align-self-center">
            <a
              class="btn btn-lg btn-primary"
              href="https://www.104.com.tw/company/152duk7c"
              target="_blank"
              rel="noopener noreferrer"
              role="button"
              >{{$t('Hr.content4')}}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="py-5"></div>

    <h5 v-html="$t('Hr.item1')"></h5>

    <p v-html="$t('Hr.itemContent1')"></p>
    <div class="py-2"></div>

    <h5 v-html="$t('Hr.item2')"></h5>
    <p v-html="$t('Hr.itemContent2')"></p>
    <ul v-html="$t('Hr.list')"></ul>
    <div class="py-2"></div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6 mb-2">
        <img src="@/assets/hr/hr2.png" class="img-fluid" alt="" />
      </div>
      <div class="col-12 col-md-6 mb-2">
        <img src="@/assets/hr/hr3.png" class="img-fluid" alt="" />
      </div>
      <div class="col-12 col-md-6 mb-2">
        <img src="@/assets/hr/hr4.png" class="img-fluid" alt="" />
      </div>
      <div class="col-12 col-md-6 mb-2">
        <img src="@/assets/hr/hr5.png" class="img-fluid" alt="" />
      </div>
      <div class="col-12 col-md-6 mb-2">
        <img src="@/assets/hr/hr6.png" class="img-fluid" alt="" />
      </div>
      <div class="col-12 col-md-6 mb-2">
        <img src="@/assets/hr/hr7.png" class="img-fluid" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>