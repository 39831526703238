<template>
  <div class="container product">
    <h1>{{$t('indexItem2.itemTitle')}}</h1>
    <div class="py-2"></div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-8 d-flex flex-column">
        <h4 class="text-danger" v-html="$t('indexItem2.contentTitle1')"></h4>
        <div class="flex-grow-1 d-flex align-items-center" v-html="$t('indexItem2.content1')">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 d-flex align-items-center">
        <img
          :src="require('@/assets/item_detail/functions1.png')"
          class="img-fluid"
          alt=""
        />
      </div>
      <div class="col-12">
        <div class="row">
          <div class="d-none d-lg-block col-lg-2"></div>
          <div class="col-12 col-md-6 col-lg-4">
            <img
              src="../../assets/item_detail/functions_1.png"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <img
              src="../../assets/item_detail/functions_2.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="py-3"></div>
    <div class="row">
      <div
        class="order-0 order-md-1 col-12 col-md-6 col-lg-8 d-flex flex-column"
      >
        <h4 class="text-danger" v-html="$t('indexItem2.contentTitle2')"></h4>
        <div class="flex-grow-1 d-flex align-items-center" v-html="$t('indexItem2.content2')">
        </div>
      </div>
      <div
        class="
          order-1 order-md-0
          col-12 col-md-6 col-lg-4
          d-flex
          align-items-center
        "
      >
        <img
          :src="require('@/assets/item_detail/functions2.png')"
          class="img-fluid"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>