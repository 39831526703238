<template>
  <div class="container product">
    <h1>{{$t('Tactel.title')}}</h1>
    <div class="py-2"></div>
    <h3 v-html="$t('Tactel.item1')"></h3>
    <p v-html="$t('Tactel.content1')"></p>
    <div class="py-2"></div>

    <h3 v-html="$t('Tactel.item2')"></h3>
    <p v-html="$t('Tactel.content2')"></p>
    <div class="py-2"></div>
    <p v-html="$t('Tactel.content3')"></p>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>
