<template>
  <div class="container product">
    <h1>{{ $t('indexItem3.itemTitle') }}</h1>
    <div class="py-2"></div>
    <p v-html="$t('indexItem3.itemSubtitle')"></p>
    <p class="font-weight-bold" v-html="$t('indexItem3.itemIntroduce')"></p>
    <h4 class="text-danger" v-html="$t('indexItem3.contentTitle1')"></h4>
    <p v-html="$t('indexItem3.content1')"></p>
    <h4 class="text-danger" v-html="$t('indexItem3.contentTitle2')"></h4>
    <p v-html="$t('indexItem3.content2')"></p>
    <div class="px-md-2 mx-md-2 px-lg-3 mx-lg-3 px-xl-5 mx-xl-5">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/gTbuIH3fHDc?rel=0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <h4 class="text-danger" v-html="$t('indexItem3.contentTitle3')"></h4>
    <p v-html="$t('indexItem3.content3')"></p>
    <h4 v-html="$t('indexItem3.contentTitle4')"></h4>
    <div class="px-md-2 mx-md-2 px-lg-3 mx-lg-3 px-xl-5 mx-xl-5">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/8Y8AxsoNYQ4?rel=0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="py-1"></div>
    <p v-html="$t('indexItem3.content4')"></p>
    <p v-html="$t('indexItem3.content5')"></p>
    <p v-html="$t('indexItem3.content6')"></p>
    <div class="row">
      <div class="col-12 col-md-6 px-md-2">
        <table
          class="table table-bordered border-secondary bg-white text-center"
        >
          <thead>
            <tr class="dark-gary-cell">
              <th scope="col">{{$t('indexItem3.table1.Fabric')}}</th>
              <th scope="col">{{$t('indexItem3.table1.Use')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="gary-cell">
              <th scope="row">{{$t('indexItem3.table1.Fabricitem')}}</th>
              <th>{{$t('indexItem3.table1.Useitem')}}</th>
            </tr>
          </tbody>
        </table>
        <table
          class="table table-bordered border-secondary bg-white text-center"
        >
          <tbody>
            <tr class="dark-gary-cell text-nowrap">
              <th class="align-middle" rowspan="2">{{$t('indexItem3.table1.Testmethod')}}</th>
              <th colspan="4">{{$t('indexItem3.table1.Washingtimes')}}</th>
              <th style="width: 30%">{{$t('indexItem3.table1.Washingmethod')}}</th>
            </tr>
            <tr class="gary-cell">
              <td>0</td>
              <td>10</td>
              <td>25</td>
              <td>50</td>
              <td class="align-middle" rowspan="4">
                {{$t('indexItem3.table1.Washingitem')}}
              </td>
            </tr>
            <tr class="light-gary-cell">
              <td>{{$t('indexItem3.table1.Testitem1')}}</td>
              <td>95</td>
              <td>95</td>
              <td>95</td>
              <td>85</td>
            </tr>
            <tr class="gary-cell">
              <td>{{$t('indexItem3.table1.Testitem2')}}</td>
              <td>4.5</td>
              <td>4.5</td>
              <td>4.5</td>
              <td>3.5</td>
            </tr>
            <tr class="light-gary-cell">
              <td>{{$t('indexItem3.table1.Testitem3')}}</td>
              <td>4.5</td>
              <td>4.5</td>
              <td>4.5</td>
              <td>3.5</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-md-6 px-md-2">
        <table
          class="table table-bordered border-secondary bg-white text-center"
        >
          <thead>
            <tr class="dark-gary-cell">
              <th scope="col">{{$t('indexItem3.table2.Fabric')}}</th>
              <th scope="col">{{$t('indexItem3.table2.Use')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="gary-cell">
              <th scope="row">{{$t('indexItem3.table2.Fabricitem')}}</th>
              <th>{{$t('indexItem3.table2.Useitem')}}</th>
            </tr>
          </tbody>
        </table>
        <table
          class="table table-bordered border-secondary bg-white text-center"
        >
          <tbody>
            <tr class="dark-gary-cell text-nowrap">
              <th class="align-middle" rowspan="2">{{$t('indexItem3.table2.Testmethod')}}</th>
              <th colspan="4">{{$t('indexItem3.table2.Washingtimes')}}</th>
              <th style="width: 30%">{{$t('indexItem3.table2.Washingmethod')}}</th>
            </tr>
            <tr class="gary-cell">
              <td>0</td>
              <td>10</td>
              <td>25</td>
              <td>50</td>
              <td class="align-middle" rowspan="4">
                {{$t('indexItem3.table2.Washingitem')}}
              </td>
            </tr>
            <tr class="light-gary-cell">
              <td>{{$t('indexItem3.table2.Testitem1')}}</td>
              <td>90</td>
              <td>90</td>
              <td>90</td>
              <td>75</td>
            </tr>
            <tr class="gary-cell">
              <td>{{$t('indexItem3.table2.Testitem2')}}</td>
              <td>4</td>
              <td>4</td>
              <td>4</td>
              <td>2.5</td>
            </tr>
            <tr class="light-gary-cell">
              <td>{{$t('indexItem3.table2.Testitem3')}}</td>
              <td>4</td>
              <td>4</td>
              <td>4</td>
              <td>2.5</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.product {
  h4 {
    margin-top: 2rem;
  }
}

.dark-gary-cell {
  background: #595959;
  color: white;
}

.gary-cell {
  background: #cbcbcb;
}

.light-gary-cell {
  background: #e7e7e7;
}
</style>