<template>
  <div class="container product">
    <h1>{{$t('ContactUs.title')}}</h1>
    <div class="py-3"></div>
    <div class="row" style="font-size: 0.75rem">
      <div class="mx-2 mx-md-auto">
        <div class="d-flex flex-fill flex-wrap flex-md-nowrap">
          <div class="col-12 col-md-auto my-2">
            <div class="card h-100 border-secondary">
              <div class="d-flex justify-content-center">
                <div class="mx-auto text-center pt-2">
                  <font-awesome-icon
                    class="text-primary"
                    icon="briefcase"
                    size="3x"
                  />
                  <h4 class="mt-2">{{$t('ContactUs.item1')}}</h4>
                </div>
              </div>
              <div class="card-body pt-2 px-2 px-md-3">
                <div class="row h-100">
                  <div class="col-auto col-md-12 mx-auto d-flex flex-column">
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title">TEL：</div>
                      <div class="flex-grow-1">886-3-272-9730</div>
                      <a
                        href="tel:+88632729730"
                        type="button"
                        class="align-right py-1 btn btn-link"
                      >
                        <font-awesome-icon
                          class="text-primary"
                          icon="phone"
                          size="lg"
                        />
                      </a>
                    </div>
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title"></div>
                      <div class="flex-grow-1">886-3-272-9700 ext.6160</div>
                      <a
                        href="tel:+88632729700"
                        type="button"
                        class="align-right py-1 btn btn-link"
                      >
                        <font-awesome-icon
                          class="text-primary"
                          icon="phone"
                          size="lg"
                        />
                      </a>
                    </div>
                    <div class="flex-grow-1"></div>
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title">FAX：</div>
                      <div class="flex-grow-1">886-3-483-0004</div>
                      <div style="height: 34px"></div>
                    </div>
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title">Email：</div>
                      <div class="flex-grow-1">sales@fefc.com.tw</div>
                      <a
                        href="mailto:sales@fefc.com.tw"
                        type="button"
                        class="align-right py-1 btn btn-link"
                      >
                        <font-awesome-icon
                          class="text-primary"
                          icon="envelope"
                          size="lg"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-auto my-2">
            <div class="card h-100 border-secondary">
              <div class="d-flex justify-content-center">
                <div class="mx-auto text-center pt-2">
                  <font-awesome-icon
                    class="text-primary"
                    icon="user"
                    size="3x"
                  />
                  <h4 class="mt-2">{{$t('ContactUs.item2')}}</h4>
                </div>
              </div>
              <div class="card-body pt-2 px-2 px-md-3">
                <div class="row">
                  <div class="col-auto col-md-12 mx-auto d-flex flex-column">
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title">TEL：</div>
                      <div class="flex-grow-1">886-3-272-9716</div>
                      <a
                        href="tel:+88632729716"
                        type="button"
                        class="align-right py-1 btn btn-link"
                      >
                        <font-awesome-icon
                          class="text-primary"
                          icon="phone"
                          size="lg"
                        />
                      </a>
                    </div>
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title"></div>
                      <div class="flex-grow-1">886-3-272-9700 ext. 6141</div>
                      <a
                        href="tel:+88632729700"
                        type="button"
                        class="align-right py-1 btn btn-link"
                      >
                        <font-awesome-icon
                          class="text-primary"
                          icon="phone"
                          size="lg"
                        />
                      </a>
                    </div>
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title"></div>
                      <div class="flex-grow-1">886-3-272-9700 ext. 6233</div>
                      <a
                        href="tel:+88632729700"
                        type="button"
                        class="align-right btn btn-link"
                      >
                        <font-awesome-icon
                          class="text-primary"
                          icon="phone"
                          size="lg"
                        />
                      </a>
                    </div>
                    <div class="flex-grow-1"></div>
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title">FAX：</div>
                      <div class="flex-grow-1">886-3-483-0004</div>
                      <div style="height: 34px"></div>
                    </div>
                    <div class="d-flex text-nowrap align-items-center">
                      <div class="contact-title">Email：</div>
                      <div class="flex-grow-1">hr@fefc.com.tw</div>
                      <a
                        href="mailto:hr@fefc.com.tw"
                        type="button"
                        class="align-right py-1 btn btn-link"
                      >
                        <font-awesome-icon
                          class="text-primary"
                          icon="envelope"
                          size="lg"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-3"></div>
    <div class="px-md-2 mx-md-2 px-lg-3 mx-lg-3 px-xl-5 mx-xl-5">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.1748621643214!2d121.11264017685866!3d25.062061770616747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346827bb5abe182f%3A0xcf706b0933b2ceaa!2z6YGg5p2x5YWI6YCy57qW57at6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1627388285282!5m2!1szh-TW!2stw"
          class="embed-responsive-item"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.contact-title {
  flex-shrink: 1;
  width: 55px;
  text-align: right;
  display: block;
}
</style>