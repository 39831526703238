<template>
  <div class="container product">
    <div class="row">
      <div class="col-12 col-md-3">
        <ul
          class="nav flex-row flex-md-column nav-pills"
          id="myTab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="about-tab"
              data-toggle="tab"
              href="#about"
              role="tab"
              aria-controls="about"
              aria-selected="true"
              >{{$t('About.list.item1')}}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="mile-tab"
              data-toggle="tab"
              href="#mile"
              role="tab"
              aria-controls="mile"
              aria-selected="false"
              >{{$t('About.list.item2')}}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="value-tab"
              data-toggle="tab"
              href="#value"
              role="tab"
              aria-controls="value"
              aria-selected="false"
              >{{$t('About.list.item3')}}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="sincerity-tab"
              data-toggle="tab"
              href="#sincerity"
              role="tab"
              aria-controls="sincerity"
              aria-selected="false"
              >{{$t('About.list.item4')}}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="she-tab"
              data-toggle="tab"
              href="#she"
              role="tab"
              aria-controls="she"
              aria-selected="false"
              >{{$t('About.list.item5')}}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="enms-tab"
              data-toggle="tab"
              href="#enms"
              role="tab"
              aria-controls="enms"
              aria-selected="false"
              >{{$t('About.list.item6')}}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-9">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="about"
            role="tabpanel"
            aria-labelledby="about-tab"
          >
            <Abouta />
          </div>
          <div
            class="tab-pane fade"
            id="mile"
            role="tabpanel"
            aria-labelledby="mile-tab"
          >
            <Mile />
          </div>
          <div
            class="tab-pane fade"
            id="value"
            role="tabpanel"
            aria-labelledby="value-tab"
          >
            <Value />
          </div>
          <div
            class="tab-pane fade"
            id="sincerity"
            role="tabpanel"
            aria-labelledby="sincerity-tab"
          >
            <Sincerity />
          </div>
          <div
            class="tab-pane fade"
            id="she"
            role="tabpanel"
            aria-labelledby="she-tab"
          >
            <SHE />
          </div>
          <div
            class="tab-pane fade"
            id="enms"
            role="tabpanel"
            aria-labelledby="enms-tab"
          >
            <Enms />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Abouta from "@/components/about/About";
import Mile from "@/components/about/Mile";
import SHE from "@/components/about/SHE";
import Sincerity from "@/components/about/Sincerity";
import Value from "@/components/about/Value";
import Enms from "@/components/about/Enms";

export default {
  setup() {
    return {};
  },
  components: {
    Abouta,
    Mile,
    SHE,
    Sincerity,
    Value,
    Enms,
  },
};
</script>

<style lang="scss" scoped>
</style>