<template>
  <div class="">
    <h1 class="text-primary">{{$t('About.page1.title')}}</h1>
    <div class="py-2"></div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-8">
        <p v-html="$t('About.page1.content1')"></p>
        <div class="py-2"></div>
        <p v-html="$t('About.page1.content2')"></p>
      </div>
      <div class="col-12 col-md-12 col-lg-4 d-flex align-items-center">
        <div class="row">
          <img
            :src="require('@/assets/about/about_bg.png')"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="py-2"></div>
    <p v-html="$t('About.page1.content3')"></p>
  </div>
</template>
<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>