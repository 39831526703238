<template>
  <div class="container product">
    <h1>{{$t('indexItem5.itemTitle')}}</h1>
    <div class="py-2"></div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-8 d-flex flex-column">
        <h4 class="text-danger" v-html="$t('indexItem5.contentTitle1')"></h4>
        <div class="flex-grow-1 d-flex align-items-center" v-html="$t('indexItem5.content1')">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 d-flex align-items-center">
        <img
          :src="require('@/assets/item_detail/style1.png')"
          class="img-fluid"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>