<template>
  <div>
    <h1 class="text-primary">{{$t('About.page5.title')}}</h1>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0">{{$t('About.page5.rule.item1')}}</p>
    <p v-html="$t('About.page5.rule.itemContent1')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0">{{$t('About.page5.rule.item2')}}</p>
    <p v-html="$t('About.page5.rule.itemContent2')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0">{{$t('About.page5.rule.item3')}}</p>
    <div class="pl-2">
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list1')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content1')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list2')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content2')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list3')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content3')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list4')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content4')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list5')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content5')"></div>
      <div class="py-2"></div>

      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list6')}}</p>
      <div class="" v-html="$t('About.page5.rule.itemContent3.content6')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list7')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content7')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list8')}}</p>
      <div class="" v-html="$t('About.page5.rule.itemContent3.content8')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list9')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content9')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list10')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content10')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list11')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content11')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list12')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content12')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list13')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content13')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list14')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content14')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list15')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content15')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list16')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content16')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list17')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content17')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list18')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content18')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list19')}}</p>
      <div v-html="$t('About.page5.rule.itemContent3.content19')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list20')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content20')"></div>
      <div class="py-2"></div>
      <p class="mb-0">{{$t('About.page5.rule.itemContent3.list21')}}</p>
      <div class="pl-3" v-html="$t('About.page5.rule.itemContent3.content21')"></div>
      <div class="py-2"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>