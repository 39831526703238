<template>
  <div class="">
    <h1 class="text-primary">{{$t('About.page6.title')}}</h1>
    <div class="py-2"></div>

    <p class="" v-html="$t('About.page6.content')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item1')"></p>
    <p class="" v-html="$t('About.page6.list.content1')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item2')"></p>
    <p class="" v-html="$t('About.page6.list.content2')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item3')"></p>
    <p class="" v-html="$t('About.page6.list.content3')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item4')"></p>
    <p class="" v-html="$t('About.page6.list.content4')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item5')"></p>
    <p class="" v-html="$t('About.page6.list.content5')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item6')"></p>
    <p class="" v-html="$t('About.page6.list.content6')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item7')"></p>
    <p class="" v-html="$t('About.page6.list.content7')"></p>
    <div class="py-2"></div>
    <p class="font-weight-bold mb-0" v-html="$t('About.page6.list.item8')"></p>
    <p class="" v-html="$t('About.page6.list.content8')"></p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>