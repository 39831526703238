<template>
  <div class="container product">
    <h1>{{$t('indexItem1.itemTitle')}}</h1>
    <div class="py-2"></div>
    <h4 class="" v-html="$t('indexItem1.itemSubtitle')"></h4>
    <div class="py-2"></div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-7 d-flex flex-column">
        <h4 class="text-danger" v-html="$t('indexItem1.contentTitle1')"></h4>
        <div class="flex-grow-1 d-flex align-items-center" v-html="$t('indexItem1.content1')">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
        <img
          :src="require('@/assets/item_detail/comfort1.png')"
          class="img-fluid"
          alt=""
        />
      </div>
    </div>

    <div class="py-4"></div>
    <div class="row">
      <div
        class="
          order-1 order-md-0
          col-12 col-md-6 col-lg-5
          d-flex
          align-items-center
        "
      >
        <img
          :src="require('@/assets/item_detail/comfort2.png')"
          class="img-fluid"
          alt=""
        />
      </div>
      <div
        class="order-0 order-md-1 col-12 col-md-6 col-lg-7 d-flex flex-column"
      >
        <h4 class="text-danger" v-html="$t('indexItem1.contentTitle2')">
        </h4>
        <div class="flex-grow-1 d-flex align-items-center" v-html="$t('indexItem1.content2')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>