<template>
  <div>
    <h1 class="text-primary">{{$t('About.page4.title')}}</h1>
    <div class="py-2"></div>
    <p v-html="$t('About.page4.content1')"></p>
    <p v-html="$t('About.page4.content2')"></p>
    <p v-html="$t('About.page4.item3')"></p>
    <ol style="list-style-type: cjk-ideographic" v-html="$t('About.page4.content3')">
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>