<template>
  <div>
    <h1 class="text-primary">{{$t('About.page3.title')}}</h1>

    <table class="my-4 mile-table table table-striped">
      <tbody>
        <tr>
          <td align="left">{{$t('About.page3.item1')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content1')"></span>
            </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item2')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content2')"></span>
            </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item3')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content3')"></span>
            </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item4')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content4')"></span>
            </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item5')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content5')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item6')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content6')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item7')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content7')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item8')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content8')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item9')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content9')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item10')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content10')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item11')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content11')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item12')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content12')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item13')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content13')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item14')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content14')"></span>
            <button type="button" class="btn btn-link">
              <font-awesome-icon
                class="text-primary"
                @click="openView(t2009Array)"
                icon="paperclip"
                size="lg"
              />
            </button>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item15')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content15')"></span>
            <button
              type="button"
              class="btn btn-link"
            >
              <font-awesome-icon
                class="text-primary"
                @click="openView(t2012Array)"
                icon="paperclip"
                size="lg"
              />
            </button>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item16')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content16')"></span>
            <button type="button" class="btn btn-link">
              <font-awesome-icon
                class="text-primary"
                @click="openView(t2013Array)"
                icon="paperclip"
                size="lg"
              />
            </button>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item17')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content17')"></span>
            <button type="button" class="btn btn-link">
              <font-awesome-icon
                class="text-primary"
                @click="openView(t2015Array)"
                icon="paperclip"
                size="lg"
              />
            </button>
          </td>
        </tr>

        <tr>
          <td align="left">{{$t('About.page3.item18')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content18')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item19')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content19')"></span>
            <button type="button" class="btn btn-link">
              <font-awesome-icon
                class="text-primary"
                @click="openView(t2017Array)"
                icon="paperclip"
                size="lg"
              />
            </button>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item20')}}</td>
          <td align="left">
           <span v-html="$t('About.page3.content20')"></span>
            <button type="button" class="btn btn-link">
              <font-awesome-icon
                class="text-primary"
                @click="openView(t2018Array)"
                icon="paperclip"
                size="lg"
              />
            </button>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item21')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content21')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item22')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content22')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item23')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content23')"></span>
          </td>
        </tr>
        <tr>
          <td align="left">{{$t('About.page3.item24')}}</td>
          <td align="left">
            <span v-html="$t('About.page3.content24')"></span>
          </td>
        </tr>
      </tbody>
    </table>
    <viewer
      ref="viewer"
      class="viewer"
      :options="options"
      :images="showImageArray"
      @inited="inited"
      rebuild
    >
      <template #default="scope">
        <div class="d-none">
          <img v-for="src in scope.images" :src="src" :key="src" />
        </div>
      </template>
    </viewer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      t2009Array: [
        require("@/assets/mile/2009/4.jpg"),
        require("@/assets/mile/2009/5.jpg"),
        require("@/assets/mile/2009/6.jpg"),
        require("@/assets/mile/2009/7.jpg"),
      ],
      t2012Array: [
        require("@/assets/mile/2012/8.jpg"),
        require("@/assets/mile/2012/9.jpg"),
      ],
      t2013Array: [require("@/assets/mile/2013/12.jpg")],
      t2015Array: [require("@/assets/mile/2015/13.jpg")],
      t2017Array: [require("@/assets/mile/2017/14.jpg")],
      t2018Array: [
        require("@/assets/mile/2018/15.jpg"),
        require("@/assets/mile/2018/16.jpg"),
        require("@/assets/mile/2018/17.jpg"),
      ],
      showImageArray: [],
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    openView(array) {
      this.showImageArray = array;
      this.$viewer.reset();

      setTimeout(() => {
        this.$viewer.show();
      }, 100);
    },
  },
  computed: {
    options() {
      return {
        inline: false,
        button: false,
        navbar: true,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: false,
        zoomable: true,
        rotatable: false,
        scalable: true,
        transition: false,
        fullscreen: true,
        keyboard: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mile-table {
  tr td:first-child {
    font-weight: bold;
    color: var(--primary);
  }
  tr td:last-child {
    vertical-align: middle;
  }
}
</style>