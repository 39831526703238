<template>
  <div class="container product">
    <h1>{{$t('indexItem6.itemTitle')}}</h1>
    <div class="py-2"></div>

    <div class="row">
      <div class="col-12 col-md-8 col-lg-9 d-flex flex-column">
        <h4 class="text-danger" v-html="$t('indexItem6.contentTitle1')">
        </h4>
        <h4 class="text-danger" v-html="$t('indexItem6.contentSubtitle1')">
        </h4>
        <div class="flex-grow-1 d-flex align-items-center" v-html="$t('indexItem6.content1')">
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3 d-flex align-items-center">
        <img
          :src="require('@/assets/item_detail/superhand.png')"
          class="img-fluid"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>