<template>
  <div>
    <h1 class="text-primary">{{$t('About.page2.title')}}</h1>
    <div class="py-2"></div>
    <p v-html="$t('About.page2.content1')"></p>
    <p v-html="$t('About.page2.content2')"></p>
    <ol style="" v-html="$t('About.page2.content3')"></ol>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>